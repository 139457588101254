.payment-modal-background {
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.payment-modal-content {
  background-color: white;
  /* Slightly less padding if you want the whole modal more compact */
  padding: 20px 24px !important; 
  border-radius: 10px;
  width: 650px;
  min-height: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.tabs-container {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.tab {
  padding: 6px 10px; /* Slightly smaller tab button */
  border: none;
  border-radius: 5px;
  background: #ccc;
  cursor: pointer;
}
.tab.active {
  background: #3498db;
  color: #fff;
}

/* Search / Transactions list containers */
.search-results,
.transactions-list {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}

.search-item {
  padding: 5px;
  cursor: pointer;
  transition: background 0.3s;
}
.search-item:hover {
  background-color: #d7f0fa;
}

.selected-client-info {
  margin-bottom: 10px;
  background-color: #f7f7f7;
  padding: 5px;
  border-radius: 5px;
}

/* LABELS */
.form-label {
  display: block;
  margin-bottom: 4px; /* Reduced from 6-8px */
  font-weight: 500;
  color: #333;
  line-height: 1.2;
}

/* COMPACT INPUTS */
.form-input {
  width: 100%;
  /* Reduced padding for smaller height */
  padding: 3px 6px;  
  font-size: 13px; 
  line-height: 1.2;  /* Tighter line spacing */
  
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.2s ease;
  box-sizing: border-box;
}

/* Focus outline still possible but smaller box shadow */
.form-input:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 1px rgba(52,152,219,0.25);
}

/* Search dropdown for client results */
.search-dropdown {
  position: absolute;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  z-index: 1000;
  margin-top: 4px;
}

.search-item {
  padding: 10px;
  cursor: pointer;
  transition: background 0.2s ease;
}
.search-item:hover {
  background: #f8f9fa;
}

/* Checkbox group spacing */
.checkbox-group {
  display: flex;
  gap: 24px;
  margin: 8px 0; /* can reduce if desired */
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 6px; /* smaller gap */
  cursor: pointer;
}

/* Transaction items in Payment History */
.transaction-item {
  padding: 12px;
  border: 1px solid #eee;
  border-radius: 6px;
  margin-bottom: 12px;
  background: #fafafa;
}

/* Month navigation in Payment History */
.month-navigation {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}
